import React, { FC } from "react";
import Image from "next/image";
import { t } from "@helpers/translate";
import GlobalSearch from "@components/common/GlobalSearch";

interface Props {
  error?: string;
}

const NotFoundPage: FC<Props> = () => {
  return (
    <div className="flow-root">
      <div className="float-left flex flex-col justify-end">
        <h1 className="text-black font-bold text-9xl">404</h1>
        <h2 className="text-black font-bold text-3xl">PAGE NOT FOUND</h2>
        <h3 className="text-black mt-6 text-2xl">Are you lost?</h3>
        <h3 className="text-black text-2xl">
          That&apos;s why you need to hire our expert guides.
        </h3>
        <div className="mt-10">
          <GlobalSearch
            className="bg-gray-100 px-4 py-6 w-full"
            placeholder={t({
              id: "global-search.placeholder2",
              message: "Search for private tours",
            })}
          />
        </div>
      </div>

      <div className="lg:float-right mx-auto">
        <Image
          className="flex lg:w-full "
          src="/gif/404_character.gif"
          alt=""
          width={400}
          height={400}
          style={{
            maxWidth: "100%",
            height: "auto",
          }}
        />
      </div>
    </div>
  );
};

export default NotFoundPage;
