import React from "react";
import { t } from "@helpers/translate";
import Head from "@components/common/Head";
import NotFoundPage from "@components/pages/NotFoundPage";
import { Container } from "@components/ui/Container";

const Page = () => {
  return (
    <div className="flex">
      <Head
        title={t({
          id: "error-page.404.seo.title",
          message: `Error 404 - Page Not Found`,
        })}
      />

      <Container className="py-4 md:py-10 w-100-vw">
        <NotFoundPage />
      </Container>
    </div>
  );
};

export default Page;
